import dayjs from "dayjs"
import { graphql, Link } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import parse from "html-react-parser"
import React from "react"
import readingTime from "reading-time"
import Announcement from "../components/Announcement/Announcement"
import Footer from "../components/Footer/footer"
import Header from "../components/header"
import Layout from "../components/layout.js"
import RelatedArticleItems from "../components/RelatedPostItems/RelatedArticleItems"
import Seo from "../components/seo"
import { StoreContext } from "../store"
import tableOfContentHeaders from "../utilities/tableOfContentHeaders"
import tableOfContentsSidebar from "../utilities/tableOfContentsSidebar"
import tableOfContentsSmoothScroll from "../utilities/tableOfContentsSmoothScroll"
import tableOfContentsToggle from "../utilities/tableOfContentsToggle"

const StateLaw = ({ location, data }) => {
  const {
    title,
    content,
    stateLaws,
    date,
    relatedArticles,
    tableOfContentsGraphQL,
    featuredImage,
  } = data.wpStateLaw

  const { viewPortWidth, isAnnouncementActive } = React.useContext(StoreContext)

  const featImg = getImage(
    featuredImage?.node?.localFile?.childImageSharp?.gatsbyImageData
  )
  const featImgAlt = featuredImage?.node?.altText

  const postsRelated = relatedArticles?.resourcesRelatedArticles
  let postsRelImgBg = ""
  let postsRelImg = ""
  let postsRelImgAlt = ""

  React.useEffect(() => {
    if (typeof window !== "undefined" && window.document) {
      tableOfContentHeaders()
      tableOfContentsToggle()
      tableOfContentsSmoothScroll()
      tableOfContentsSidebar(
        window.document.querySelector(".toc-main-content-wrapper"),
        false
      )
      window.addEventListener("scroll", () => {
        tableOfContentsSidebar(
          window.document.querySelector(".toc-main-content-wrapper"),
          false
        )
      })
    }

    window.addEventListener("resize", () => {
      tableOfContentHeaders()
    })
  }, [])

  return (
    <Layout>
      <Seo pageData={data.wpStateLaw} location={location} />

      <div className="patterns-articles-template-default single single-patterns-articles postid-430 wp-embed-responsive is-resources">
        <div
          className={`Page ${isAnnouncementActive ? "has-announcement" : ""} `}
        >
          <Announcement />
          <Header siteTitle={`GoodHire`} viewPortWidth={viewPortWidth} />

          <div className="Page-content">
            <section className="Body">
              <div className="Wrap">
                <div className="Grid with-12 has-xSmallGaps">
                  {/* Breadcrumb */}
                  <div className="is-12">
                    <p className="Resource-breadcrumb is-article">
                      <Link
                        className="BreadcrumbParent"
                        to="/background-checks/"
                      >
                        State Laws
                      </Link>
                      <span aria-current="page" className="BreadcrumbChild">
                        {title}
                      </span>
                    </p>
                  </div>

                  {/* Title */}
                  <div className="is-12">
                    <header>
                      <h1 className="Content-title">
                        {title} Background Checks
                      </h1>
                    </header>
                  </div>

                  {/* Content Byline */}
                  <div className="is-12">
                    <div className="Content-byline">
                      <time dateTime={date} className="Content-date">
                        {dayjs(date).format("MMMM DD, YYYY")}
                      </time>

                      <div className="Readtime">
                        <p>
                          <span className="Readtime-display color-dark-gray">
                            <i aria-hidden="true" className="fa fa-clock-o"></i>{" "}
                            {readingTime(content).text}
                          </span>
                        </p>
                      </div>
                    </div>
                  </div>

                  {/* Hero */}
                  {featImg && (
                    <div className="Content-image is-12">
                      <GatsbyImage
                        style={{ width: "100%" }}
                        image={featImg}
                        alt={featImgAlt}
                      />
                    </div>
                  )}
                </div>

                {/* Content */}
                <div className="Grid with-12 has-xSmallGaps toc-main-content-wrapper">
                  <div className="is-4 is-12--small toc">
                    <div className="Blog-sidebar-toc">
                      <h3 className="Toc-header">Table of Contents</h3>
                      <div className="Blog-sidebar-toc-inner">
                        <p>Click a chapter to scroll directly to it.</p>
                        <hr />
                        {tableOfContentsGraphQL &&
                          parse(tableOfContentsGraphQL)}
                        <hr />
                      </div>
                    </div>
                  </div>
                  <div className="is-8 is-12--small">
                    <div className="Body-content">
                      {content && parse(content)}
                    </div>
                  </div>
                </div>

                {/* Related Articles */}
                <div className="is-12">
                  <div className="Blog-sidebar-related">
                    <hr />
                    <h3>Keep Reading</h3>
                    {postsRelated &&
                      postsRelated.map(post => {
                        postsRelImg = getImage(
                          post.featuredImage?.node?.localFile?.childImageSharp
                            ?.gatsbyImageData
                        )

                        postsRelImgBg = convertToBgImage(postsRelImg)
                        postsRelImgAlt = post.featuredImage.node.altText

                        return (
                          <article
                            key={post.id}
                            className="Content patterns-articles type-patterns-articles status-publish has-post-thumbnail hentry"
                          >
                            <Link to={`/resources/articles/${post.slug}/`}>
                              <div className="Content-thumbnail">
                                <BackgroundImage
                                  Tag="div"
                                  {...postsRelImgBg}
                                  className="Content-thumbnail-image"
                                ></BackgroundImage>
                              </div>
                              <div className="Content-text">
                                <header className="Content-header">
                                  <h3 className="Content-title">
                                    {post.title}
                                  </h3>
                                </header>

                                <div className="Content-excerpt">
                                  {parse(post.excerpt)}
                                </div>
                              </div>
                            </Link>
                          </article>
                        )
                      })}
                    {(postsRelated === null || postsRelated === undefined) && (
                      <RelatedArticleItems />
                    )}
                  </div>
                </div>
              </div>
            </section>
          </div>
          <Footer />
        </div>
      </div>
    </Layout>
  )
}

export default StateLaw

export const query = graphql`
  query ($id: String!) {
    wpStateLaw(id: { eq: $id }) {
      title
      excerpt
      content
      id
      slug
      databaseId
      date(formatString: "LL")
      tableOfContentsGraphQL
      featuredImage {
        node {
          altText
          sourceUrl
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(width: 1232, placeholder: BLURRED)
            }
          }
          mediaDetails {
            height
            width
          }
        }
      }
      relatedArticles {
        resourcesRelatedArticles {
          ... on WpArticle {
            id
            title
            excerpt
            slug
            featuredImage {
              node {
                altText
                localFile {
                  childImageSharp {
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
      }
      stateLaws {
        stateLawsUpdatedDate
        stateLawsBadge {
          altText
          localFile {
            publicURL
            childImageSharp {
              gatsbyImageData(
                formats: AUTO
                layout: FULL_WIDTH
                placeholder: BLURRED
              )
            }
          }
        }
      }
      seo {
        title
        metaDesc
        metaRobotsNoindex
        metaRobotsNofollow
        opengraphTitle
        opengraphDescription
        opengraphPublishedTime
        opengraphModifiedTime
        opengraphSiteName
        opengraphType
        readingTime
      }
      richSnippetSchemaJson
    }
  }
`
